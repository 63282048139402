<template>
  <section>
    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      class="ma-0 white hero2"
      height="500"
    >
      <v-carousel-item
        style="height:100%"
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-row style="height:100%" class="hidden-sm-and-down">
          <v-col cols="6">
            <div style="width: 100%;height: 100%">
              <v-img
                alt="alt name"
                class=""
                cover
                :src="slide.image.data.full_url"
                width="100%"
                height="100%"
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div
              style="width:100%;height:100%;background: white"
              class="d-flex flex-column align-center justify-center"
            >
              <div class="display-2 primary--text text-center">
                {{ slide[lang + 'title'] }}
              </div>
              <div
                class="body-1 primary--text text-center my-8"
                style="fontSize:20px !important"
              >
                {{ slide[lang + 'body'] }}
              </div>
              <base-btn
                :href="slide.link"
                class=""
                color="primary"
                square
                style="width:120px"
                target="_blank"
              >
                {{ $t('more') }}
              </base-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" style="height:300px" class="hidden-md-and-up">
            <div style="width: 100%;height: 100%">
              <v-img
                alt="alt name"
                class=""
                cover
                :src="slide.image.data.full_url"
                width="100%"
                height="100%"
              />
            </div>
          </v-col>
          <v-col style="height:300px" cols="12">
            <div
              style="width:100%;height: 100%;background: white"
              class="d-flex flex-column align-center justify-center"
            >
              <div class="display-2 primary--text text-center">
                {{ slide[lang + 'title'] }}
              </div>
              <div
                class="body-1 primary--text text-center my-8"
                style="fontSize:20px !important"
              >
                {{ slide[lang + 'body'] }}
              </div>
              <base-btn
                :href="slide.link"
                class=""
                color="primary"
                square
                style="width:120px"
                target="_blank"
              >
                {{ $t('more') }}
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  data() {
    return {
      loading: false,
      slides: [],
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    async getSlides() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/advertisement?fields=*,image.data.full_url&filter[slider]=1`
        );
        this.slides = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSlides();
  },
};
</script>

<style>
.hero2 {
  box-shadow: 0px 8px 15px -12px black !important;
}

.hero {
  /* background: white;
  -webkit-box-shadow: 0 0 0 4px white, 0 6px 4px black;
  -moz-box-shadow: 0 0 0 4px white, 0 6px 4px black;
  box-shadow: 0 0 0 4px white, 0 6px 4px black; */
  position: relative !important;
  /* -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; */
}

.hero:after {
  content: '';
  left: 10px;
  right: 10px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  background: transparent;
  -webkit-box-shadow: 0 0 0 4px white, 0 6px 4px black;
  -moz-box-shadow: 0 0 0 4px white, 0 6px 4px black;
  box-shadow: 0 0 0 4px white, 0 6px 4px black;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}

.hero:before,
.hero:after {
  content: '';
  position: absolute;
  /* z-index: -1; */
  -webkit-box-shadow: 0 0 20px black;
  -moz-box-shadow: 0 0 20px black;
  box-shadow: 0 0 20px black;
  top: 50%;
  bottom: 0;
  left: 10px;
  right: 10px;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.hero:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  -moz-transform: skew(8deg) rotate(3deg);
  -ms-transform: skew(8deg) rotate(3deg);
  -o-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}

.box {
  width: 70%;
  height: 200px;
  background: #fff;
  margin: 40px auto;
}
</style>
